import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const EwclContainer = styled.div`
  width: 100vw;
  max-width: 1170px;
  margin: auto;
  font-family: 'Roboto', sans-serif, ariel;
  font-size: 10px;
`;

const HeadlineContainer = styled.div`
  box-sizing: border-box;
  background: #000000;
  text-transform: uppercase;
  padding: 10px;
  margin: 20px auto;
  max-width: 980px;
  text-align: center;
  h1 {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    color: #fff;
    @media (max-width: 801px) {
      font-size: 15px;
    }
  }
  .bold {
    font-size: 44px;
    font-weight: 500;
    @media (max-width: 801px) {
      font-size: 26px;
    }
  }
`;

const LogoContainer = styled.div`
  width: calc(100% - 20px);
  margin: 20px auto;
  max-width: 400px;
  @media (max-width: 801px) {
    max-width: 300px;
  }
  img {
    max-width: 100%;
    margin-bottom: 5px;
  }
`;

const QuestionsContainer = styled.div`
  box-sizing: border-box;
  background: #e0e1e4;
  border: none;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  max-width: 980px;
  color: #000;
  padding: 10px 20px 10px;
  margin: auto;
  .questionsHeadline {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }
`;

const ThreeItems = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 801px) {
    flex-wrap: wrap;
  }
`;
const ThreeItemsContent = styled.div`
  width: 31%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  color: #000;
  img {
    width: 65px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
  }
  @media (max-width: 801px) {
    width: 100%;
  }
`;

const DividerContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, #e9eaec 0%, #868a95 100%);
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: solid 1px #676767;
  min-height: 70px;
  h2 {
    text-shadow: 0 3px 6px #333;
    margin: 0;
    text-align: center;
    padding: 0;
    line-height: 1.2;
    font-size: calc(16px + 0.7vw);
    font-weight: 400;
  }
  img {
    max-width: 100%;
    margin-bottom: 0;
  }
`;

const QuestionList = styled.div`
  ul {
    column-count: 1;
    column-gap: 20px;
    font-size: 15px;
    font-weight: 600;
    padding-left: 5px;
    font-style: italic;
    @media (max-width: 801px) {
      column-count: 1;
    }
  }
  li {
    list-style-type: none;
    margin-bottom: 10px;
    background: url('https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/ejfqDisc.png')
      0 4px/12px no-repeat;
    padding-left: 20px;
  }
`;

// const DisclaimerContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   img {
//     max-width: 100%;
//   }
//   p {
//     font-size: 10px;
//     margin-left: 20px;
//   }
//   @media (max-width: 801px) {
//     flex-wrap: wrap;
//     justify-content: center;
//     flex-direction: column-reverse;
//   }
// `;

const EwclThankyou = () => (
  <EwclContainer>
    <HeadlineContainer>
      <h1>
        <span className="bold"> You've been matched</span>
        <br />
        with our featured provider, West Capital Lending
      </h1>
    </HeadlineContainer>
    <LogoContainer>
      <img
        src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/wclLogo.png"
        alt="West Capital Lending"
      />
    </LogoContainer>
    <QuestionsContainer>
      <p className="questionsHeadline">
        We've grown our independent community bank into a top nationwide SBA
        lender, with a nationwide team of more than 200 residential mortgage
        originators - but we never lose sight of our neighbors and communities.
      </p>
      <ThreeItems>
        <ThreeItemsContent>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/ejfqHomeIcon.png"
            alt="Home Icon"
          />
          <p>Zero closing cost options are available with most scenarios.</p>
        </ThreeItemsContent>
        <ThreeItemsContent>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/ejfqInstitutionIcon.png"
            alt="Institution Icon"
          />
          <p>
            Communication is key - we pledge to keep you informed every step of
            the way.
          </p>
        </ThreeItemsContent>
        <ThreeItemsContent>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/ejfqMoneyIcon.png"
            alt="Money Icon"
          />
          <p>Interest rates offered are among the lowest in the industry.</p>
        </ThreeItemsContent>
      </ThreeItems>
      <DividerContainer>
        <h2>Have questions? West Capital Lending can help!</h2>

        {/* <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooQuestionIcon.png"
          alt="Question Icon"
        /> */}
      </DividerContainer>
      <QuestionList>
        <ul>
          <li>Our innovative technology streamlines the mortgage process.</li>
          <li>
            Multiple investor options means we will find the best solution for
            you.
          </li>
          <li>
            Exceptional customer service makes for a hassle-free experience for
            our customers.
          </li>
          <li>
            Our dedicated professionals truly care about YOU and your
            experience.
          </li>
        </ul>
      </QuestionList>
    </QuestionsContainer>
  </EwclContainer>
);

const googleFontLink =
  'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900';

export default withThankyouWrapper(EwclThankyou, googleFontLink);
